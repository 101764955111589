<template>
  <div>
    <div class="hero">
      <div id="particles-js">
        <img id="logo" alt="logo" src="../assets/logo.png">
        <img id="flame" src="../assets/flame-bg.png">
      </div>
      <nav>
        <a class="action" href="#agenda">programação</a>
        <a class="action" href="#como-chegar">como chegar</a>
      </nav>
    </div>

    <AgendaPreview />
    <LocationMap />
    <BodyFooter />
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import BodyFooter from '@/components/BodyFooter.vue';
import AgendaPreview from '@/components/AgendaPreview.vue';
import LocationMap from '@/components/LocationMap.vue';

export default {
  name: 'HomeView',
  components: {
    BodyFooter,
    AgendaPreview,
    LocationMap
  },
  mounted() {
    particlesJS.load('particles-js', 'assets/particles.json', function () {
      // console.log('callback - particles.js config loaded');
    })
  }
}
</script>



<style>
@keyframes fadeIn {
  from {
    opacity: 0;
    /* transform: translate(-50%, 0) scale(1.03); */
  }

  to {
    opacity: 1;
    /* transform: translate(-50%, 0) scale(1); */
  }
}

@keyframes scaleIn {
  from {
    transform: scale(1.1);
  }

  to {
    transform: scale(1);
  }
}

.hero {
  /* border: 1px solid red; */

  animation: fadeIn 0.75s ease-in;
  height: 50rem;

  background: linear-gradient(var(--theme-black), rgb(150, 0, 0));

  #particles-js {
    /* border: 2px solid blue; */
    height: 100%;

    #logo {
      animation: scaleIn 0.75s ease-in;
      position: relative;
      left: 0;
      translate: (-50%, -50%);
      top: 5.5rem;
      height: 50rem;
      max-height: 33rem;
      z-index: 1;
    }

    #flame {
      animation: fadeIn 0.75s ease-in;
      position: absolute;
      margin: 0 auto;
      translate: (-50%, -50%);
      top: -8.5rem;
      left: calc(50% - 26rem);
      width: 55rem;
    }
  }


  nav {
    position: relative;
    top: -9rem;
    font-size: 1.2rem;
    animation: fadeIn 1.5s ease-in;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: .5rem;        
    padding: 0;

    .action {
      text-decoration: none;
      border: 2px solid red;
      border-radius: .5rem;
      padding: .5rem;
      color: white;
      display: inline-block;
      width: 10rem;      
      font-weight: normal;
      background-color: rgba(255, 0, 0, .15);

      &:hover {
        border-color: white;
        transition: .3s ease;
        text-shadow: 1px 1px black;
        background-color: rgba(255, 0, 0, .5);
      }
    }
  }



}

.particles-js-canvas-el {
  position: absolute;
  left: 0;
  top: 0;
  height: 50rem !important;
  z-index: 0;
}

@media screen and ((max-width : 320px) or (max-width : 600px) or (max-width : 992px)) {
  .hero {

    height: 38rem;

    .action {
      top: -7rem;
    }

    #particles-js {

      /* border: 2px solid white; */

      #logo {
        height: 20rem;
        top: 8rem;
      }

      #flame {
        position: absolute;
        overflow: hidden;
        width: auto;
        max-width: 100vw;
        height: 40rem;
        /* border: 1px solid greenyellow; */
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  .particles-js-canvas-el {
    height: 38rem !important;
  }
}
</style>

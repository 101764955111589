<template>
    <footer>
        <section id="links">
            <nav>
                <a href="https://www.instagram.com/america.rockbar/" target="_blank"><img
                        src="../assets/instagram.webp" /></a>
                <a href="https://www.facebook.com/america.rockbar/" target="_blank"><img
                        src="../assets/facebook.png" /></a>
            </nav>
        </section>

        <p><strong>América Rock Bar</strong><sup>©</sup> {{ currentYear }}, todos os direitos reservados.</p>
        <p>Av Dr. Ruge Ramos, nº 1612, São Bernardo do Campo - SP</p>


        <hr />
        <div class="mage-by">
            Made by
            <a href="https://aeonsoft.com.br" target="_blank">
                <img src="../assets/aeonsoft.png" />
                Aeonsoft Tecnologia
            </a>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'BodyFooter',
    data() {
        return {
            currentYear: new Date().getFullYear()
        }
    }
}
</script>

<style scoped>
footer {
    color: white;
    border-top: .1rem solid gray;
    min-height: 5rem;
    padding: 2rem;
    opacity: .4;

    p {
        margin: 0;
    }

    .mage-by {
        color: white;

        img {
            height: 0.9rem;
        }

        a {
            color: white;
            text-decoration: none;
        }
    }

    #links {

        nav {
            position: relative;
            display: block;
            margin: 1rem;
            padding: 0;

            img {
                height: 2.5rem;
                padding: .5rem;
            }
        }

    }
}

@media screen and ((max-width : 320px) or (max-width : 600px) or (max-width : 992px)) {
    footer {
        #links {
            nav {
                margin: 0;
                img {                    
                    height: 3rem;
                    padding: 1rem;
                }
            }

        }
    }
}
</style>
<template>
    <a id="como-chegar"></a>
    <section class="como-chegar">
        <h2>Como Chegar</h2>
        <p>O <strong>América Rock Bar</strong> está localizado Av Dr. Rudge Ramos, nº 1612, em São Bernardo do Campo-SP,
            bem pertinho da Faculdade Anhanguera e ao lado da rodovia Anchieta, facinho de chegar.</p>
        <p>
            Consulte o mapa abaixo para mais detalhes
        </p>
        <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d98352.33339299406!2d-46.706538598798446!3d-23.638722104467497!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce5d1195ca3b71%3A0xb9c929ebfe7cbab8!2sAm%C3%A9rica%20Rock%20Bar!5e0!3m2!1sen!2sbr!4v1730137307771!5m2!1sen!2sbr"
            width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"></iframe>
    </section>
</template>

<script>
export default {
    name: 'LocationMap'
}
</script>

<style>
.como-chegar {
    color: white;
    margin: 2rem 5rem;

    h2 {}

    p {
        text-align: justify;
    }
}

@media screen and (max-width : 600px) {
    .como-chegar {
        margin: 2rem;
    }
}

@media screen and (max-width : 320px) {
    .como-chegar {
        margin: 2rem 1rem;
    }
}
</style>